const prefix = `https://${config.CDN_URL}`;

export default {
    install(Vue) {
        Vue.directive('imgPrefix', {
            inserted: function (el, binding) {
                if (el.tagName === 'IMG') {
                    const src = el.getAttribute('src');

                    if (src && isValidURL(prefix) && !src.startsWith(prefix) && !isValidURL(src)) {
                        el.setAttribute('src', prefix + src);
                    }
                }
            }
        });
    }
};

function isValidURL(url) {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(url);
}